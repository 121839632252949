.content {
  width: 100%;
  height: 100%;
  position: relative;

  > .leftImage {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

