.content {
  background: var(--foreground-rgb);
  padding: 30px 0;
  border-radius: 12px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;

  > .leftContainer {
    > .titleDesc {
      color: #ffffff;
      font-size: 13px;
      margin-bottom: 10px;

      &::before {
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #9873ff;
        margin-right: 10px;
      }
    }

    > .title {
      font-weight: bold;
      font-family: CrimsonText-Italic;
      font-size: 38px;
      line-height: 45px;
      color: var(--app-text-color);
    }

    > p {
      margin-top: 24px;
      font-weight: 400;
      font-size: 16px;
      color: var(--app-text-color);
      padding-right: 80px;

      > span:nth-of-type(2) {
        color: #79f7ff;
        margin: 0 5px;
      }

      > span:nth-of-type(4) {
        color: #79f7ff;
        margin: 0 5px;
      }
    }

    > .btnContainer {
      // text-align: center;
      > .btn {
        border: none;
        min-width: 200px;
        margin-top: 30px;
        padding: 0 50px;
        height: 50px;
        background: linear-gradient(90deg, #9873ff 0%, #3948d1 100%);
        // background: var(--foreground-rgb);
        border-radius: 3px;
        color: var(--app-text-color);

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    > .manyCustomer {
      margin-top: 30px;
      color: #ffffff;

      > .trustedContainer {
        display: inline-block;

        > span:nth-of-type(2) {
          color: #9873ff;
          margin: 0 2px;
        }
      }
    }
  }

  > .rightContainer {
    > img {
      width: 100%;
      // object-fit: contain;
    }
  }
}

.bottomScrollContainer {
  z-index: 999 !important;
  background: var(--foreground-rgb);
  width: 100vw;
  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: nowrap !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 8px 16px !important;
  margin-left: -16px !important;
  margin-right: -16px !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.bottomContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  color: white;

  > .stylingBy {
    font-family: Inter, Inter;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }

  > .sortSpan {
    display: inline-block;
    border-radius: 6px;
    padding: 6px 12px;
    cursor: pointer;
  }
}

.custom-tooltip .ant-tooltip {
  transform: translate(125px, 20px) !important;
  /* 使用 !important 确保样式生效 */
  border-width: 1px;
  border-style: solid;
  border-color: white;
}

@media screen and (min-width: 900px) {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    > .rightContainer {
      display: block;
    }
  }
}

@media screen and (max-width: 900px) {
  .content {
    display: block;

    grid-template-columns: 1fr;
    column-gap: 0;

    > .leftContainer {
      > .btnContainer {
        text-align: center;
      }
    }

    // > .rightContainer {
    //   display: none;
    // }
  }
}

@media screen and (max-width: 576px) {
  .content {
    > .leftContainer {
      > .title {
        font-size: 32px;
        line-height: 1;
      }

      > p {
        margin-top: 15px;
        padding-right: 0;
      }

      > .btnContainer {
        width: 100%;

        > .btn {
          width: 100%;
          margin-top: 20px;
        }
      }

      > .stepContainer {
        margin-top: 20px;
      }

      > .stepContainer {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        color: var(--app-text-color);

        .liItem {
          display: flex;
          margin-bottom: 10px;

          > .lineConatiner {
            display: flex;
            flex-direction: column;

            align-items: center;
            justify-content: center;
            margin-right: 10px;

            > .line {
              margin: 5px 0 10px 0;
              height: 100%;
              width: 2px;
              border-top: 0px;
              border-left: 2px dashed var(--app-text-color);
            }
          }

          .itemTitle {
            margin-top: 0;
          }
        }
      }

      > .manyCustomer {
        > .trustedContainer {
          margin-top: 10px;
          display: block;
        }
      }
    }
  }
}

